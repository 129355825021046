import { generateTimeSlots } from '@/utils/utils';
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import OverlayPanel from 'primevue/overlaypanel';

export interface ISlotEditable {
    from_time_at: string;
    to_time_at: string;
    time_label: string;
    title: string;
}

@Options({
    name: 'TimeSlot'
})
export default class TimeSlot extends Vue {
    @Prop()
    readonly timeStart: string;

    @Prop()
    readonly timeEnd: string;

    @Prop({ default: 60 })
    readonly interval: number;

    @Prop({ default: [] })
    slotsEditable: ISlotEditable[];

    @Prop({ default: [] })
    readonly slotsFixed: ISlotEditable[];

    @Prop()
    readonly titleAddSlot: string;

    @Prop()
    disabled: boolean;

    @Prop({ default: false })
    onlyLeft: boolean;

    @Prop({default: false})
    headerDay: Date;

    genTimeSlots: string[] = [];

    created() {
        if(this.interval <= 0) {
            console.error("Time Slot - interval must be > 0");
            return;
        }

        this.genTimeSlots = generateTimeSlots(this.timeStart, this.timeEnd, this.interval);
    }

    @Watch('timeStart')
    onTimeStartChange() {
        this.genTimeSlots = generateTimeSlots(this.timeStart, this.timeEnd, this.interval);
    }

    @Watch('timeEnd')
    onTimeEndChange() {
        this.genTimeSlots = generateTimeSlots(this.timeStart, this.timeEnd, this.interval);
    }

    @Watch('interval')
    onIntervalChange() {
        this.genTimeSlots = generateTimeSlots(this.timeStart, this.timeEnd, this.interval);
    }

    @Watch('genTimeSlots')
    onGenTimeSlots() {
        this.$emit('timeSlotsChange', this.genTimeSlots);
    }

    overlayData: any[] = null;
    toggle(event: MouseEvent, slots: any[]) {
        if (!slots?.length) {
          return;
        } 
        this.overlayData = slots
            .map(sl => {
                return {
                    time_label: sl.time_label,
                    title: sl.title
                }
            });
        
        (this.$refs.tsList as OverlayPanel).toggle(event);
    }
    
    addSlotEditable(ts) {
        this.slotsEditable.push(ts);
    }

    removeSlotEditable(ts) {
        this.$emit('update:slotsEditable', this.slotsEditable.filter(s => s.from_time_at != ts.from_time_at));
    }

    get timeSlotValues() {
        if (!this.timeStart || !this.timeEnd || !this.interval) {
            return [];
        }
        const res = [];

        for (let i = 0; i < this.genTimeSlots.length; i++) {
            const time = this.genTimeSlots[i];

            const nextTime = this.genTimeSlots.length - 1 >= i + 1 ? this.genTimeSlots[i + 1] : null;
            const longTime = `${time}:00`;
            const longNextTime = `${nextTime}:00`;

            res.push({
                time_label: time,
                from_time_at: longTime,
                to_time_at: longNextTime,
                is_last: i == this.genTimeSlots.length - 1,
                slot_editable: this.slotsEditable.find(e => e.from_time_at == longTime) || null,
                slots_fixed: this.slotsFixed.filter(e => {
                    const diff = moment(e.to_time_at, 'HH:mm:ss').diff(moment(e.from_time_at, 'HH:mm:ss'), 'minute');
                    if (diff > this.interval) {
                        return e.from_time_at <= longTime && e.to_time_at >= longNextTime;
                    }
                    return e.from_time_at >= longTime && e.to_time_at <= longNextTime;
                })
            });
        }
        return res;
    }
}