import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")!

  return (_openBlock(), _createBlock(_component_AutoComplete, {
    forceSelection: "",
    dropdown: "",
    modelValue: _ctx.currentValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentValue = $event)),
    field: _ctx.field,
    suggestions: _ctx.suggestions,
    onComplete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.search($event))),
    onItemSelect: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onSelect($event))),
    placeholder: _ctx.$attrs['placeholder']
  }, null, 8, ["modelValue", "field", "suggestions", "placeholder"]))
}