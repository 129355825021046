import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "p-field" }
const _hoisted_2 = { for: "description" }
const _hoisted_3 = { class: "list-attachments" }
const _hoisted_4 = {
  key: 0,
  class: "attachment"
}
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Message = _resolveComponent("Message")!
  const _component_PDialog = _resolveComponent("PDialog")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    draggable: true,
    keepInViewPort: true,
    minX: 0,
    minY: 0,
    header: _ctx.$t('generic.attachments_label.tooltip')
  }, _ctx.$attrs, {
    onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reset())),
    ref: "modal",
    style: { 'width': '400px'}
  }), {
    footer: _withCtx(() => [
      (_ctx.upload)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            label: _ctx.$t('generic.upload_label'),
            icon: "pi pi-upload",
            iconPos: "right",
            class: "p-button-success",
            onClick: _ctx.uploadAttachments,
            disabled: !_ctx.file || !_ctx.description || _ctx.requestPending
          }, null, 8, ["label", "onClick", "disabled"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            label: _ctx.$t('btn_add_new_label'),
            icon: "pi pi-plus",
            iconPos: "right",
            class: "p-button-success",
            onClick: _ctx.stackAttachments,
            disabled: !_ctx.file || !_ctx.description || _ctx.requestPending
          }, null, 8, ["label", "onClick", "disabled"]))
    ]),
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.$t('generic.attachment_description_label')) + " ", 1),
          _createVNode(_component_InfoIcon, { text: "generic.attachment_description_label.tooltip" })
        ]),
        _createVNode(_component_InputText, {
          id: "description",
          modelValue: _ctx.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.description = $event)),
          editorStyle: "height: 100px",
          name: "description"
        }, null, 8, ["modelValue"])
      ]),
      _withDirectives(_createVNode("p", null, null, 512), [
        [_directive_t, 'generic.choose_attachment_file_label']
      ]),
      _createVNode("div", _hoisted_3, [
        _createVNode(_component_FileUpload, {
          ref: "uploader",
          mode: "basic",
          auto: true,
          accept: "application/pdf,image/*",
          maxFileSize: _ctx.maxFileSize,
          onUploader: _ctx.openFile,
          customUpload: true,
          chooseLabel: _ctx.$t('generic.choose_file_label'),
          disabled: _ctx.requestPending
        }, null, 8, ["maxFileSize", "onUploader", "chooseLabel", "disabled"]),
        (_ctx.file)
          ? (_openBlock(), _createBlock("div", _hoisted_4, [
              _createVNode("span", null, _toDisplayString(_ctx.file.name), 1),
              _createVNode(_component_Button, {
                class: "p-button-text",
                icon: "pi pi-times-circle",
                onClick: _ctx.reset,
                disabled: !_ctx.file || _ctx.requestPending
              }, null, 8, ["onClick", "disabled"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.requestPending)
        ? (_openBlock(), _createBlock("p", _hoisted_5, _toDisplayString(_ctx.$t('loading_label')), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.errors, (err, idx) => {
        return (_openBlock(), _createBlock(_component_Message, {
          severity: "error",
          key: `error-${idx}`,
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(err), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 16, ["header"]))
}