import { Vue, Options } from 'vue-class-component';

@Options({
    name: 'FormPage'
})
export default class FormPage extends Vue {
    protected modelFormName = 'model';

    protected modelFormHash = null;

    created() {
        window.addEventListener('beforeunload', this.beforeunload);
    }

    unmounted() {
        window.removeEventListener('beforeunload', this.beforeunload);
    }

    async checkBeforeRouteLeave(next) {
        if (this.isFormChanged) {
            const res = await this.$confirmMessage(this.$t("msg_form_changed"));
            next(res);
        } else {
            next();
        }
    }

    beforeunload(event) {
        if (this.isFormChanged) {
            event.preventDefault();
            return event.returnValue = this.$t('msg_form_changed');
        }
        return false;
    }

    get isFormChanged() {
        return this.modelFormHash != JSON.stringify(this.getModelForm());
    }

    protected modelFormChanged() {
        this.modelFormHash = JSON.stringify(this.getModelForm());
    }

    private getModelForm() {
        return this[this.modelFormName] || null;
    }
}