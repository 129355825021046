import { City } from '@/model/api/Domain';
import { domainService } from '@services/domain.service';
import { isObject } from '@turf/helpers';
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    name: 'ServiceDuration'
})
export default class ServiceDuration extends Vue {
    @Prop()
    modelValue: any;

    @Prop({ default: false })
    showClear: boolean;

    values = [
        { name: "5", code: 5 },
        { name: "10", code: 10 },
        { name: "15", code: 15 },
        { name: "20", code: 20 },
        { name: "25", code: 25 },
        { name: "30", code: 30 },
        { name: "35", code: 35 },
        { name: "40", code: 40 },
        { name: "45", code: 45 },
        { name: "50", code: 50 },
        { name: "55", code: 55 },
        { name: "60", code: 60 }
    ];

    get duration() {
        return this.values.find(v => v.code == this.modelValue);
    }
    set duration(value) {
        this.$emit('update:modelValue', value.code);
    }

    created() {
        if (this.showClear) {
            this.values.unshift({ name: "-", code: null });
        }
    }
}