import UserTable from "../UserTable";

import { mixins, Options } from "vue-class-component";
import { CRUD } from "@/services/base/crud";

export declare class IUserDynamicTable {
    $props: {
        hideHeader: boolean;
        cols: any[];
        service: CRUD<any, any>;
        filtersSchema?: any;
        stateKey?: string;
        deletMsgFn?: (data) => string;
        deleteSuccessMsg?: string;
        deleteErrorMsg?: string
        showDelete?: boolean
        resetPwdMsgFn?: (data) => string;
        resetPwdSuccessMsg?: string;
        resetPwdErrorMsg?: string
        showResetPassword?: boolean
        dopplegangerMsgFn?: (data) => string;
        dopplegangerSuccessMsg?: string;
        dopplegangerErrorMsg?: string
        showDoppleganger?: boolean
        showEdit?: boolean
        customIndexRequestFn?: (p) => Promise<any>;
    }
}

@Options({
    name: "UserDynamicTable",
    props: ['cols', 'hideHeader'],
})

export default class UserDynamicTable extends mixins(UserTable) {
    
    getValueOrDefault(value: any, defaultValue: any) {
        if (typeof value === 'boolean') return value;
        return value || defaultValue;
    }
}