import { Order } from '@/model/api/Order';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { Provider, ProviderTechnician } from '@/model/api/Provider';
import { Service } from '@/model/api/Service';
import moment from 'moment';
import { FilterMatchMode } from 'primevue/api';
import { CRUD } from './base/crud';

class ProviderService extends CRUD<Provider, any> {
    readonly endPoint = `providers`;
        
    public getServiceById(id: number): Promise<any> {
        return this.get<any>(`provider-services/${id}`);
    }

    public async posAutocomplete(q: string, providerId: number) {
        const filters = {};

        if (q) {
            filters['q'] = {
                value: q,
                matchMode: FilterMatchMode.CONTAINS
            };
        }

        const response = await this.get<PaginatedResponse<any>>(
            `${this.endPoint}/${providerId}/orders/pos`,
            {
                params: {
                    filters
                }
            }
        );

        response.data = response.data
            .map(x => ({ id: x.id, name: x.business_name }));
            
        return response;
    }

    public async technicianAutocomplete(q: string, providerId: number) {
        const filters = {};

        if (q) {
            filters['q'] = {
                value: q,
                matchMode: FilterMatchMode.CONTAINS
            };
        }

        const response = await this.get<PaginatedResponse<any>>(
            `${this.endPoint}/${providerId}/orders/technicians`,
            {
                params: {
                    filters
                }
            }
        );

        response.data = response.data
            .map(x => ({ id: x.id, name: `${x.name} ${x.surname}` }));
            
        return response;
    }

    public getAvailableServices(providerId: number) {
        return this.get<Service[]>(`${this.endPoint}/${providerId}/available-services`);
    }    
}

export const providerService = new ProviderService();