import { City } from '@/model/api/Domain';
import { domainService } from '@services/domain.service';
import { isObject } from '@turf/helpers';
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    name: 'CityAutocomplete'
})
export default class CityAutocomplete extends Vue {
    @Prop()
    modelValue: any;

    @Prop({ default: null })
    provinceId: number;

    suggestions: City[] = [];
    currentValue: City = null;

    created() {
        this.suggestions = [{ id: null, name: this.$t('all_plur_fem_label') }];
        this.refreshCurrentValue(this.modelValue);
    }

    @Watch('modelValue')
    onModelValueChanged(newValue) {
        this.refreshCurrentValue(newValue);
    }

    refreshCurrentValue(value: any) {
        if (!value) {
            this.currentValue = null;
            return;
        }

        if (isObject(value)) {
            this.currentValue = value;
        } else {
            this.currentValue = this.suggestions.find(s => s.id == value);
        }
    }

    async search({ query }) {
        const resp = await domainService.getCitiesAutocomplete(query, this.provinceId);
        this.suggestions = [
            { id: null, name: this.$t('all_plur_fem_label') },
            ...resp.data
        ];

        this.$emit('complete', this.suggestions);
    }

    onSelect(event) {
        this.currentValue = event.value;

        this.$emit('update:modelValue', isObject(this.modelValue) ? event.value : event.value.id);
        this.$emit('select', event);
    }
}