import i18n from "@/i18n"

export enum FeeTypeEnum {
    YEAR = 'YEAR0',
    MONTH = 'MONTH'
}

export const feeTypesForDropdown = [
    { name: i18n.global.t('fee_type.monthly'), code: FeeTypeEnum.MONTH },
    { name: i18n.global.t('fee_type.yearly'), code: FeeTypeEnum.YEAR }
];