import { domainService } from '@services/domain.service';
import { posService } from '@services/pos.service';
import { posCategoryService } from '@services/posCategory.service';
import { providerService } from '@services/provider.service';
import { serviceCategoryService } from '@services/serviceCategory.service';
import { providerServiceBackofficeService } from '@services/providerService.service';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    name: 'MyAutocomplete'
})
export default class MyAutocomplete extends Vue {
    @Prop()
    modelValue: any;

    @Prop()
    type!: string;

    @Prop({ default: null })
    meta: any;

    @Prop({ default: 'name' })
    field: string;

    @Prop({ default: [] })
    additional: any[] = [];

    suggestions: any[] = [];
    currentValue: any = null;

    created() {
        this.suggestions = [{ id: null, name: this.$t('generic.all_sing_male_label') }];
        this.refreshCurrentValue(this.modelValue);
    }

    @Watch('modelValue')
    onModelValueChanged(newValue) {
        this.refreshCurrentValue(newValue);
    }

    isObject(value) {
        return value && typeof value === 'object';
    }

    refreshCurrentValue(value: any) {
        if (!value) {
            this.currentValue = null;
            return;
        }

        if (this.isObject(value)) {
            this.currentValue = value;
        } else {
            this.currentValue = this.suggestions.find(s => s.id == value);
        }
    }

    async search({ query }) {
        let resp = null;

        this.suggestions = [
            { id: null, name: this.$t('all_plur_fem_label') }
        ];

        switch (this.type) {
            case 'city':
                resp = await domainService.getCitiesAutocomplete(query, this.meta?.province_id);
                break;
            case 'province':
                resp = await domainService.getProvincesAutocomplete(query, this.meta?.region_id);
                break;
            case 'region':
                resp = await domainService.getRegionsAutocomplete(query, this.meta?.country_id);
                break;
            case 'country':
                resp = await domainService.getCountriesAutocomplete(query);
                break;
            case 'service_category':
                resp = await serviceCategoryService.autocomplete(query);
                break;
            case 'pos_category':
                resp = await posCategoryService.autocomplete(query);
                break;
            case 'services-for-provider':
                resp = await domainService.getServicesForProviderAutocomplete(query);
                break;
            case 'services-all':
                resp = await domainService.getServicesAllAutocomplete(query);
                break;
            case 'provider':
                resp = await domainService.getProvidersAutocomplete(query);
                break;
            case 'pos-all':
                resp = await domainService.getPosAutocomplete(query);
                break;
            case 'pos':
                resp = await providerService.posAutocomplete(query, 0);
                break;
            case 'provider-pos':
                resp = await providerService.posAutocomplete(query, this.meta?.providerId);
                break;
            case 'provider-technicians':
                resp = await providerService.technicianAutocomplete(query, this.meta?.providerId);
                break;
            case 'pos-provider':
                resp = await posService.providerAutocomplete(query, this.meta?.posId);
                break;
            case 'pos-technicians':
                resp = await posService.technicianAutocomplete(query, this.meta?.posId);
                break;
            case 'pos-services':
                resp = await posService.serviceAutocomplete(query, this.meta?.posId);
                break;
            default:
                throw new Error("MyAutocomplete: type not supported");
        }

        if (this.suggestions.length == 1) {
            this.suggestions = [...this.suggestions, ...resp.data, ...this.additional];
        }

        this.$emit('complete', this.suggestions);
    }

    onSelect(event) {
        this.currentValue = event.value;

        this.$emit(
            'update:modelValue', 
            this.isObject(this.modelValue) 
                ? event.value 
                : event.value.id
            );
        this.$emit('select', event);
    }
}