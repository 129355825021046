import { HttpService } from './base/http.service';
import { PaginatedResponse } from "@/model/api/PaginatedResponse";
import { Country, Province, Region, VAT } from '@/model/api/Domain';
import { FilterMatchMode } from "primevue/api";
import { Service } from '@/model/api/Service';

class DomainService extends HttpService {

    private readonly endPoint = `domain`;

    public getCountriesAutocomplete(q: string) {
        const filters = {};
        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }

        return this.get<PaginatedResponse<Country>>(
            `${this.endPoint}/countries`,
            {
                params: {
                    filters
                }
            }
        );
    }

    public getRegionsAutocomplete(q: string, country_id: number) {
        const filters = {};
        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }

        return this.get<PaginatedResponse<Region>>(
            `${this.endPoint}/regions`,
            {
                params: {
                    filters
                }
            }
        );
    }

    public getProvincesAutocomplete(q: string, region_id?: number) {
        const filters = {};

        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }

        if (region_id) {
            filters['region_id'] = {
                value: region_id,
                matchMode: FilterMatchMode.EQUALS
            };
        }

        return this.get<PaginatedResponse<Province>>(
            `${this.endPoint}/provinces`,
            {
                params: {
                    filters
                }
            }
        );
    }

    public getProvincesByRegions(q: string, region_id?: number[]) {
        const filters = {};

        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }

        if (region_id?.length) {
            filters['region_id'] = {
                value: region_id,
                matchMode: FilterMatchMode.IN
            };
        }

        return this.get<PaginatedResponse<Province>>(
            `${this.endPoint}/provinces`,
            {
                params: {
                    filters
                }
            }
        );
    }

    public getCitiesAutocomplete(q: string, province_id?: number) {
        const filters = {};
        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }
        if (province_id) {
            filters['province_id'] = {
                value: province_id,
                matchMode: FilterMatchMode.EQUALS
            };
        }

        return this.get<PaginatedResponse<Province>>(
            `${this.endPoint}/cities`,
            {
                params: {
                    filters
                }
            }
        );
    }

    public getVAT() {
        return this.get<VAT[]>(`${this.endPoint}/vat`);
    }

    public getServicesForProviderAutocomplete(q: string) {
        const filters = {};
        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }
        return this.get<PaginatedResponse<Service>>(
            `${this.endPoint}/services-for-provider`,
            {
                params: {
                    filters
                }
            }
        );
    }

    public getServicesAllAutocomplete(q: string) {
        const filters = {};
        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }
        return this.get<PaginatedResponse<Service>>(
            `${this.endPoint}/services-all`,
            {
                params: {
                    filters
                }
            }
        );
    }

    public getProvidersAutocomplete(q: string, pos_id?: number) {
        const filters = {};
        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.STARTS_WITH
            };
        }
        if (pos_id) {
            filters['pos_id'] = {
                value: pos_id,
                matchMode: FilterMatchMode.EQUALS
            };
        }
        return this.get<PaginatedResponse<Service>>(
            `${this.endPoint}/providers`,
            {
                params: {
                    filters
                }
            }
        );
    }


    public getPosAutocomplete(q: string) {
        const filters = {};
        if (q) {
            filters['name'] = {
                value: q,
                matchMode: FilterMatchMode.CONTAINS
            };
        }
        return this.get<PaginatedResponse<Service>>(
            `${this.endPoint}/pos`,
            {
                params: {
                    filters
                }
            }
        );
    }

}

export const domainService = new DomainService();



