import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createBlock(_component_Dropdown, {
    modelValue: _ctx.duration,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.duration = $event)),
    options: _ctx.values,
    optionLabel: "name"
  }, null, 8, ["modelValue", "options"]))
}