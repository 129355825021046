import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { POSCategory } from '@/model/api/POS';
import { CRUD } from './base/crud';

class POSCategoryService extends CRUD<POSCategory, any> {
    readonly endPoint = `pos-categories`;

    public autocomplete(q: string): Promise<PaginatedResponse<POSCategory>> {
        return this.get(`${this.endPoint}/autocomplete`, { params: { q: q } });
    }
}

export const posCategoryService = new POSCategoryService();