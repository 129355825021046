import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { POS } from '@/model/api/POS';
import { Service } from '@/model/api/Service';
import { FilterMatchMode } from 'primevue/api';
import { CRUD } from './base/crud';

class POSService extends CRUD<POS, any> {
    readonly endPoint = `pos`;

    public async providerAutocomplete(q: string, posId: number) {
        const filters = {};

        if (q) {
            filters['q'] = {
                value: q,
                matchMode: FilterMatchMode.CONTAINS
            };
        }

        const response = await this.get<PaginatedResponse<any>>(
            `${this.endPoint}/${posId}/orders/provider`,
            {
                params: {
                    filters
                }
            }
        );

        response.data = response.data
            .map(x => ({ id: x.id, name: x.business_name }));
            
        return response;
    }

    public async technicianAutocomplete(q: string, posId: number) {
        const filters = {};

        if (q) {
            filters['q'] = {
                value: q,
                matchMode: FilterMatchMode.CONTAINS
            };
        }

        const response = await this.get<PaginatedResponse<any>>(
            `${this.endPoint}/${posId}/orders/technicians`,
            {
                params: {
                    filters
                }
            }
        );

        response.data = response.data
            .map(x => ({ id: x.id, name: `${x.name} ${x.surname}` }));
            
        return response;
    }

    public async serviceAutocomplete(q: string, posId: number) {
        const filters = {};

        if (q) {
            filters['q'] = {
                value: q,
                matchMode: FilterMatchMode.CONTAINS
            };
        }

        const response = await this.get<PaginatedResponse<any>>(
            `${this.endPoint}/${posId}/orders/services`,
            {
                params: {
                    filters
                }
            }
        );

        response.data = response.data
            .map(x => ({ id: x.id, name: `${x.name}` }));
            
        return response;
    }

    importFromExcel(file: File) {
        const formData = new FormData();
        formData.append("file", file);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        return this.post<void>(`${this.endPoint}/import`, formData, options);
    }

    public getAvailableServices(posId: number) {
        return this.get<Service[]>(`${this.endPoint}/${posId}/available-services`);
    } 
}

export const posService = new POSService();