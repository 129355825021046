export enum AttachmentTypeEnum {
    GENERIC = "GENER",
    BY_PROVIDER_FOR_POS = "PRPOS",
    EXAM_PREP = "EXPRE"
}

export enum AttachmentIdEnum {
    ENTITY = "ENTITY",
    POS_SERVICE = "POS_SERVICE",
    PROVIDER_SERVICE = "PROVIDER_SERVICE"
}