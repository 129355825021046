import i18n from "@/i18n";
import { PrimeVueEvent } from "@/model/ui/PrimeVueTypes";
import { SortOrderEnum } from "@/model/ui/PrimeVueTypes";
import { CRUD } from "@/services/base/crud";
import { Options } from "vue-class-component";
import { Model, Prop, Vue } from "vue-property-decorator";

@Options({
    name: "DynamicTable",
})
export default class TableMixin extends Vue {
    @Prop()
    readonly cols: any[] = null;

    @Prop()
    readonly service: CRUD<any, any> = null;

    @Prop({ default: {} })
    filters: any;

    @Prop()
    readonly stateKey: string;

    @Prop()
    readonly deletMsgFn: (data) => string;

    @Prop({ default: 'Cancellazione completata con successo' })
    readonly deleteSuccessMsg: string;

    @Prop({ default: 'Cancellazione non riuscita' })
    readonly deleteErrorMsg: string;

    @Prop({ default: true })
    readonly showDelete: boolean;

    @Prop({ default: true })
    readonly showEdit: boolean;

    @Prop({ default: true })
    readonly showArchive: boolean;

    @Prop({ default: false })
    readonly showSelected: boolean;

    @Prop({ default: false })
    readonly showDetails: boolean;

    @Prop()
    readonly customIndexRequestFn: (p) => Promise<any>;

    firstLoading: boolean = true;

    private loading: boolean = false;
    private checked: boolean = false;

    set isLoading(value: boolean) {
        this.loading = value;
        this.$emit('loadingChange', this.isLoading);
    }

    get isLoading() {
        return this.loading;
    }

    get isChecked() {
        return this.checked;
    }

    get filterActive() {
        return this.filterActive;
    }

    dataset: any[] = null;

    totalRecords = 0;

    get showGlobalFilter() {
        return this.filters?.global;
    }

    get dt(): any {
        return this.$refs.dt;
    }

    public applyFilter() {
         this.loadData(this.getFilters());
    }

    public resetFilters() {
        for (const key in this.filters) {
            this.filters[key].value = null;
        }
        this.$emit('resetFilters');
        this.applyFilter();
    }

    public toggle() {
        this.$emit('toggle');
        this.checked = !this.checked;
        this.applyFilter();
    }
    
    public rowClass(data) {
        let css = "";
        if (data.greyed === 1) css = css + 'greyed';
        if (data.selected === 1) {
            if (css.length > 0) css = css + ' ';
            css = css + ' selected';
        }
        return css;
        
    }

    private async loadData(params?: PrimeVueEvent) {
        params?.originalEvent && delete params.originalEvent;

        if (!params) params = this.getFilters();

        try {
            this.isLoading = true;

            const {
                filters,
                sortField,
                sortOrder
            } = params;

            const p = {
                sortField,
                sortOrder,
                per_page: params?.rows,
                page: (params?.first ? params.first / params.rows : 0) + 1,
                checked: this.showArchive ? this.checked : true,
                selected: this.showSelected
            } as any;

            if (filters && Object.keys(filters).length) {
                p.filters = { ...filters };
            }

            let data = [];
            let total = 0;
            if (this.customIndexRequestFn) {
                const resp = await this.customIndexRequestFn(p);
                data = resp.data || resp;
                total = resp.total || data.length;
            } else {
                const resp = await this.service.index(p);
                data = resp.data;
                total = resp.total;
            }

            this.dataset = data;
            this.totalRecords = total;

            this.$emit('totalRecordsChange', this.totalRecords);

        } catch (error) {
            // undefined error is for canceled request
            if (error) {
                console.error(error);
                this.$errorMessage("", "Caricamento dataset interrotto");
            }
        } finally {
            this.isLoading = false;
            this.firstLoading = false;
        }
    }

    private getFilters() {
        return {
            ...this.dt.createLazyLoadEvent(),
            filters: this.filters
        }
    }

    onApplyFilter(filterCallback?: Function) {
        filterCallback && filterCallback();
        this.applyFilter();
    }

    onClearFilter() {
        this.$emit('resetFilters');
        this.loadData(this.getFilters());
    }

    onGlobalFilterSubmit() {
        this.loadData(this.getFilters());
    }

    onPage(e: any): any {
        const pe = e as PrimeVueEvent;
        this.loadData(pe);
    }

    onSort(e: Event): any {
        const pe = e as PrimeVueEvent;
        this.loadData(pe);
    }

    onDelete(data: any) {
        if (this.$attrs.onDelete) {
            this.$emit('delete', data);
            return;
        }
        let message = i18n.global.t('confirm_delete');
        if (this.deletMsgFn) {
            message = this.deletMsgFn(data);
        }

        this.$confirmMessage(message)
            .then(async response => {
                if (response) {
                    this.$waitFor(
                        async () => {
                            await this.service.remove(data);
                            this.loadData(this.getFilters());
                            this.$successMessage(this.deleteSuccessMsg);
                        },
                        this.deleteErrorMsg
                    )
                }
            })

    }

    getComponent(component) {
        if (typeof component === "string") {
            return `vue:${component}`;
        } else {
            return component;
        }
    }

    mounted() {
        this.loadData(this.getFilters());
    }
}