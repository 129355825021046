import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { ServiceCategory } from '@/model/api/Service';
import { CRUD } from './base/crud';

class ServiceCategoryService extends CRUD<ServiceCategory, any> {
    readonly endPoint = `service-categories`;

    public autocomplete(q: string): Promise<PaginatedResponse<ServiceCategory>> {
        return this.get(`${this.endPoint}/autocomplete`, { params: { q: q } });
    }
}

export const serviceCategoryService = new ServiceCategoryService();