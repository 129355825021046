import { mixins, Options}  from "vue-class-component";

import { CRUD }          from "@/services/base/crud";

import Table from "./Table";

export declare class IAppTable {
    $props: {
        service             : CRUD<any, any>;
        filtersSchema?      : any;
        stateKey?           : string;
        deletMsgFn?         : (data) => string;
        deleteSuccessMsg?   : string;
        deleteErrorMsg?     : string
        showDelete?         : boolean
        showEdit?           : boolean
    }
}

@Options({})
export default class AppTable extends mixins(Table) {
}