import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "list-attachments" }
const _hoisted_2 = {
  key: 0,
  class: "attachment"
}
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Message = _resolveComponent("Message")!
  const _component_PDialog = _resolveComponent("PDialog")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    modal: true,
    class: "p-fluid",
    draggable: true,
    keepInViewPort: true,
    minX: 0,
    minY: 0,
    header: _ctx.$t('admin.pos.import_modal.title')
  }, _ctx.$attrs, {
    onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reset())),
    ref: "modal",
    style: { 'width': '400px'}
  }), {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('generic.upload_label'),
        icon: "pi pi-plus",
        iconPos: "right",
        class: "p-button-success",
        onClick: _ctx.doImport,
        disabled: !_ctx.file || _ctx.requestPending
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    default: _withCtx(() => [
      _withDirectives(_createVNode("p", null, null, 512), [
        [_directive_t, 'generic.choose_excel_file_label']
      ]),
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_FileUpload, {
          ref: "uploader",
          mode: "basic",
          auto: true,
          maxFileSize: _ctx.maxFileSize,
          onUploader: _ctx.openFile,
          customUpload: true,
          chooseLabel: _ctx.$t('generic.choose_file_label'),
          disabled: _ctx.requestPending,
          accept: ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        }, null, 8, ["maxFileSize", "onUploader", "chooseLabel", "disabled"]),
        (_ctx.file)
          ? (_openBlock(), _createBlock("div", _hoisted_2, [
              _createVNode("span", null, _toDisplayString(_ctx.file.name), 1),
              _createVNode(_component_Button, {
                class: "p-button-text",
                icon: "pi pi-times-circle",
                onClick: _ctx.reset,
                disabled: !_ctx.file || _ctx.requestPending
              }, null, 8, ["onClick", "disabled"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.requestPending)
        ? (_openBlock(), _createBlock("p", _hoisted_3, _toDisplayString(_ctx.$t('loading_label')), 1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.errors, (err, idx) => {
        return (_openBlock(), _createBlock(_component_Message, {
          severity: "error",
          key: `error-${idx}`,
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(err), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 16, ["header"]))
}