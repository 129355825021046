import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { ProviderService, ProviderTechnician } from '@/model/api/Provider';
import { IBaseApiAttachmentModel } from '@/model/common/BaseApiModel';
import { AttachmentTypeEnum } from '@/model/enums/AttachmentTypeEnum';
import axios from 'axios';
import { CRUD } from './base/crud';

class ProviderServiceBackofficeService extends CRUD<ProviderService, any> {
    readonly endPoint = `provider-services`;

    public indexByProvider(providerId: number, params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        return this.get<PaginatedResponse<ProviderService>>(`providers/${providerId}/services`, { params, cancelToken });
    }

    public getAttachments(providerServiceId: number, type?: AttachmentTypeEnum) {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        return this.get<IBaseApiAttachmentModel[]>(`${this.endPoint}/${providerServiceId}/attachments`, {
            params: {
                type: type,
                cancelToken
            }
        });
    }

    uploadAttachment(providerServiceId: number, file: File, description: string, type: AttachmentTypeEnum) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        formData.append("description", description);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<any>(`${this.endPoint}/${providerServiceId}/upload-attachment`, formData, options);
    }


    deleteAttachment(provAttachId: number) {
        return this.delete<void>(`provider-service-attachments/${provAttachId}`);
    }

    public getAvailableTechnicians(providerServiceId: number) {
        return this.get<ProviderTechnician[]>(
            `provider-services/${providerServiceId}/available-technicians`
        );
    }  

    public getAvailableTechniciansForPosAtDate(serviceId: number, posId: number, date: string, techId: number) {
        return this.get<ProviderTechnician[]>(
            `provider-services/${serviceId}/available-technicians-for-pos/${posId}/${date}/${techId}`
        );
    }
}

export const providerServiceBackofficeService = new ProviderServiceBackofficeService();