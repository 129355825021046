import { posService } from "@services/pos.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class PosImportDialog extends Vue {
  file: File = null;
  errors: string[] = [];

  async openFile(event) {
    this.reset();
    this.file = event.files[0];
  }

  maxFileSize() {
    return process.env.MAX_FILE_SIZE || 10000000;
  }

  async doImport() {
    this.errors = [];
    this.requestPending = true;

    try {
      await posService.importFromExcel(this.file);
      this.reset();

      this.$successMessage(this.$t('generic.import_success'));
      this.close();
      this.$emit('success');

    } catch (error) {
      console.error(error);

      if (error?.status === 422) {
        const fieldsInError = Object.keys(error.data.errors || []);

        fieldsInError.forEach(k => {
          this.errors.push(error.data.errors[k][0]);
        });

        if (!fieldsInError.length && error.data.message) {
          this.errors = [error.data.message];
        }
      } else {
        this.$errorMessage(this.$t('generic_error'));
      }
    }

    this.requestPending = false;
  }

  reset() {
    this.file = null;
    (this.$refs.uploader as any)?.clear();
    this.errors = [];
  }

  close() {
    (this.$refs.modal as any)?.close();
  }
}