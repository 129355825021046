
import { createSequenceFromTo } from "@/utils/utils";
import { defineComponent, onMounted, ref, toRefs } from "vue";
import moment from "moment";
export default defineComponent({
  name: "DaySlots",

  props: {
    serviceDayPlan: {
      type: Object,
    },
    duration: {
      default: 30,
    },
    from: {
      type: Number,
      default: 8,
    },
    to: {
      type: Number,
      default: 20
    }
  },
 
  setup(props) {
    const {
      from, to
    } = toRefs(props);

    const timeRange = ref([])
    
    function initTimeRange() {
      const range = createSequenceFromTo(
        from.value  as number * 60, 
        to.value    as number * 60, 
        (props.duration as number)
      )
      .map(x => {
        return [Math.floor(x / 60), (x % 60)]
          .map(t => t.toString().padStart(2, '0'))
          .join(':');
      });

      timeRange.value = range;
    }

    function isTimeSlot(time: string, to: number) {
      const end = to.toString().padStart(2, '0').concat(':00:00');
      return moment(time, 'HH:mm:ss').isSameOrAfter(moment(end, 'HH:mm:ss')) ? 'mday__row__slots--empty' : 'mday__row__slots';
    }

    onMounted(() => {
      initTimeRange();
    });

    return {
      timeRange,
      isTimeSlot
    }
  }
})

