import { IBaseApiAttachmentModel } from '@/model/common/BaseApiModel';
import { AttachmentTypeEnum } from '@/model/enums/AttachmentTypeEnum';
import { HttpService } from './base/http.service';

class EntityService extends HttpService {

    public getAttachments(entityId: number, type?: AttachmentTypeEnum) {
        return this.get<IBaseApiAttachmentModel[]>(`entities/${entityId}/attachments`, {
            params: {
                type: type
            }
        });
    }

    uploadAttachment(entityId: number, file: File, description: string, type: AttachmentTypeEnum) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        formData.append("description", description);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<any>(`entities/${entityId}/upload-attachment`, formData, options);
    }

    deleteAttachment(entityId: number) {
        return this.delete<void>(`entities-attachments/${entityId}`);
    }
}

export const entityService = new EntityService();