import moment from "moment";
import { Options, Vue } from "vue-class-component";

import { 
  MyAutocomplete  
} from "@components";
import { Prop } from "vue-property-decorator";
import { authStore } from "@/modules/auth/store";
import { Service } from "@/model/api/Service";
import { posCalendarService } from "@services/posCalendar.service";
import OverlayPanel from "primevue/overlaypanel";
import { calendarStore } from "@/modules/calendar/store";
import { CalendarRoutesEnum } from "@/modules/calendar/router/CalendarRoutesEnum";

@Options({
  components:  {
    MyAutocomplete
  }
})
export default class PosCalendar extends Vue {
  
  @Prop({ default: "0" }) readonly forcustomer!: string;  
  
  /**
   * Resulst of fetching events
   */
  get events() {
    return calendarStore.getters.vueCalPosMonthEvents || [];
  }

  startDate   = new Date();
  currentDate = new Date();
  
  /**
   * Selected service from dropdown
   */
  serviceId?: number = null; 

  /**
   * Available service options for the dropdown
   */
  services: Service[] = null;

  /**
   * A date is valid only if is in the future
   * @param date 
   * @returns 
   */
  isValidDate(date: any) {
    if (moment(date).isBefore(moment(), 'day')) {
      return false;
    }
    return true;
  }

  getClassCell(cell: any, events: any[]) {
    if (!events?.length && !this.isValidDate(cell.formattedDate)) {
      return 'blocked';
    } 

    else if (events.length) {
      const isSoldOut = events.every(e => {
        const { count, total } = e.data;
        return count === total;
      });
      if (isSoldOut) return "sold_out" ;
        
      const isSale = events.some(e => {
        const { count, total } = e.data;
        return count < total;
      });
      if (isSale) return "av_and_sold_gt0";
    } 

    else {
      return 'no_services';
    }
  }

  getClassText (event: any) {
    if (0 == event.data.count){
      return "text_sold_out";
    }
  }

  applyFilters() {
    this.loadEvents();
  }

  onCellClick(date: Date, events: any[]) {
    if (!events?.length) {
      return;
    }

    this.$router.push({
      name: CalendarRoutesEnum.POS_CALENDAR_DATE,
      params: {
        day: (date as any).format('YYYY-MM-DD'),
        forcustomer : this.forcustomer
      }
    })
  }

  onViewChange(event: any) {
    this.currentDate = new Date(event.startDate);
    this.loadEvents();
  }

  /*
  overlayDate: string = null; 
  overlayData: any[] = null;
  onMouseOver(event: MouseEvent, cell: any, events: any[]) {
    this.overlayDate = cell.startDate.format('DD MMMM YYYY');

    if (!events?.length) {
      return;
    } 
    
    this.overlayData = events
      // .sort((
      //   {service_id: aid}, 
      //   {service_id: bid}
      // ) => aid - bid)
      .map(e => {
        return {
          service_name: e.data.value.service.name,
          available: e.data.value.available_slots_count,
          purchased: e.data.value.purchased_slots_count
        }
      });
    
    (this.$refs.evDetails as OverlayPanel).toggle(event);

  }
  */

  get currentPosId() {
    return authStore.getters.me?.entity_id;
  }

  private async loadEvents() {
    const result = this.$waitFor( async () => {
      return await calendarStore.actions.fetchPosEvents({
        currentPosId : this.currentPosId,
        month        : (this.currentDate as any).format('YYYY-MM'),
        serviceId    : this.serviceId,
        forcustomer  : this.forcustomer,
        available    : false
      });
    });
    return result;
  }

  
  servicesIsLoading: boolean = false;
  private async loadServices() {
    try {
      this.servicesIsLoading = true;

      const empty : Service[] = [{
        name: this.$t('generic.btn_all') , id: 0, is_active: false, image_path_url: "",
        country_id: 0, is_service_provider: false, note: "",
        wp_image_url: "", wp_page_url: "",
        commission_min_amt: 0, commission_percent: 0,
        pos_commission_min_amt: 0, pos_commission_percent: 0,
        customer_descr: "", service_categories: [], country: null,
        pos_categories: [], visibility: [], attachments: []
      }]
      this.services = 
        await posCalendarService.fetchServices(this.currentPosId, false);
      this.services = empty.concat(this.services);

    } catch (error) {
      alert("Error");
    } finally {
      this.servicesIsLoading = false;
    }
  }

  created() {
    this.loadServices();
  }

  mounted() {
    this.loadEvents();
  }

  updated() {
    this.fixCalendar();
  }

  fixCalendar() {
    const vss = document.querySelectorAll<HTMLElement>('.vuecal__cell:not(.vuecal__cell--out-of-scope)');
    vss.forEach((vs) => {
      vs.setAttribute('style', 'display: "block"; min-height: 120px !important');
    });

    const values = ["23", "24", "25", "26", "27", "28", "29", "30", "31"];
    const ooss = document.querySelectorAll<HTMLElement>('.vuecal__cell--out-of-scope');

    ooss.forEach((oos) => {
        if (oos.hasChildNodes()) {
          const header = oos.firstChild.textContent.substring(0, 2);
          oos.removeChild(oos.firstChild);
          if (!values.includes(header)) {
            oos.setAttribute('style', 'display: "none"; min-height: 0 !important');
          }
        }
    });
  }
}