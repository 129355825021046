import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({})
export default class ImageInput extends Vue {
  @Prop()
  readonly modelValue: File; 
  
  file: File = null; 

  get imageUrl() {
    return this.file && URL.createObjectURL(this.file)
  }

  get id() {
    return (this.$attrs as any).id;
  }
  
  onFileChange(event: Event) {
    console.debug("File event", event);
    this.file = (event.target as HTMLInputElement).files[0];

    this.$emit('select', this.file);
    this.$emit('update:modelValue', this.file);
  }
}