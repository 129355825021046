import { providerServiceBackofficeService } from '@services/providerService.service';
import { posServiceService } from "@services/posService.service";
import { entityService } from '@services/entity.service';
import { Options, Vue } from "vue-class-component";
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import { AttachmentIdEnum, AttachmentTypeEnum } from '@/model/enums/AttachmentTypeEnum';
import { IBaseApiAttachmentModel } from '@/model/common/BaseApiModel';

@Options({})
export default class FileUploadDialog extends Vue {
  file: File = null;
  description: string = null;
  errors: string[] = [];
  attach: IBaseApiAttachmentModel = null;
  
  @Prop() readonly object_id!: number;
  @Prop() readonly attachment_type!: AttachmentTypeEnum;
  @Prop() readonly attachment_id!: AttachmentIdEnum;
  @Prop({default: true})
  readonly upload!: boolean;
  
  async openFile(event) {
    this.reset();
    this.file = event.files[0];
  }

  maxFileSize() {
    return process.env.MAX_FILE_SIZE || 10000000;
  }

  stackAttachments() {
    this.attach = {id: this.object_id, _file: this.file, filename: this.file.name, description: this.description, type: this.attachment_type};
    (this.$refs.uploader as any).clear();
    this.close();
    this.description = null;
    this.$emit('success', this.attach);
  }

  uploadAttachments() {
    this.$waitFor(async () => {
      switch (this.attachment_id) {
        case AttachmentIdEnum.ENTITY:
          this.attach = await entityService.uploadAttachment(this.object_id, this.file, this.description, this.attachment_type);
          break;
        case AttachmentIdEnum.POS_SERVICE:
          this.attach = await posServiceService.uploadAttachment(this.object_id, this.file, this.description, this.attachment_type);
          break;
        case AttachmentIdEnum.PROVIDER_SERVICE:
          this.attach = await providerServiceBackofficeService.uploadAttachment(this.object_id, this.file, this.description, this.attachment_type);
          break;
        default:
          break;
      }
      (this.$refs.uploader as any).clear();
      this.close();
      this.description = null;
      this.$emit('success', this.attach);
    });
  }

  reset() {
    this.file = null;
    (this.$refs.uploader as any)?.clear();
    this.errors = [];
  }

  close() {
    (this.$refs.modal as any)?.close();
  }
}