import { notificationsService } from "@services/notifications.service";
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";

import { Notification } from "@/model/api/Notification";
import moment from "moment";
import { ProviderTechnicianRoutesEnum } from "@/modules/provider-technician/router";
import { NotificationEnum } from "@/model/enums/NotificationEnum";
import { POSRoutesEnum } from "@/modules/pos/router";
import { OrdersRoutesEnum } from "@/modules/orders/router";
import { ProviderServicesRoutesEnum } from '@/modules/services/router/providerPortfolioServices';
import { Prop } from "vue-property-decorator/lib/decorators/Prop";

export default class Notifications extends Vue {

  @Prop({ default: 5 }) shown: number;
  @Prop({ default: false }) dashboard: boolean;


  notifications: Notification[] = null;

  created() {
    this.loadNotifications();
  }

  dateFormatted(date) {
    if (moment().isSame(date, "day")) {
      return `Oggi, ${moment(date).tz('UTC').format("HH:mm")}`;
    } else {
      return moment(date).tz('UTC').format("DD/MM/YYYY HH:mm");
    }
  }

  goToNotification(notification: Notification) {
    console.debug("Clicked", notification);
    switch(notification.type) {
      case NotificationEnum.POS_CUSTOMER_ORDER:
        this.$router.push({ name: OrdersRoutesEnum.ORDER_DETAILS, params: {orderId: notification.object_id}});
        break;
      case NotificationEnum.POS_POS_ORDER:
        this.$router.push({ name: POSRoutesEnum.PORTFOLIO_EDIT, params: {portfolioId: notification.object_id}});
        break;
      case NotificationEnum.PROVIDER_POS_ORDER:
        this.$router.push({ name: OrdersRoutesEnum.ORDER_DETAILS, params: {orderId: notification.object_id}});
        break;
      case NotificationEnum.PROVIDER_FEEDBACK_RECEIVED:
        this.$router.push({ name: ProviderServicesRoutesEnum.SERVICES_FEEDBACKS_LIST });
        break;
      case NotificationEnum.POS_FEEDBACK_RECEIVED:
        this.$router.push({ name: POSRoutesEnum.POS_CUSTOMER_FEEDBACKS });
        break;
      case NotificationEnum.TECHNICIAN_POS_ORDER:
      default:
        this.$router.push({ name: ProviderTechnicianRoutesEnum.APPOINTMENTS });
        break;
    }
  }

  async loadNotifications() {
    const response = await notificationsService.fetchNotifications('true');

    this.notifications = response.slice(0, this.shown);
  }

  setReadAll() {
    notificationsService.setReadAll();
  }

  mounted() {
    //this.$store.dispatch("updateNotificationCount")
  }
}
