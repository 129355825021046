import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { City, Country, Province, Region } from '@/model/api/Domain';
import { feeTypesForDropdown } from '@/model/enums/FeeTypeEnum';
import { domainService } from '@services/domain.service';
import moment from 'moment-timezone'

import {
    Form as VForm,
    Field as VField,
    ErrorMessage as VError
} from 'vee-validate';
import { authStore } from '@/modules/auth/store';
import { UserRoleEnum } from '@/model/enums/UserRoleEnum';
import { POS, POSCategory } from '@/model/api/POS';
import { posCategoryService } from '@services/posCategory.service';
import { networkService } from '@services/network.service';
import { Network } from '@/model/api/Network';

@Options({
    name: 'POSForm',
    components: {
        VForm,
        VField,
        VError
    }
})
export default class POSForm extends Vue {
    @Prop()
    model: POS;

    @Prop({ default: false })
    readOnly: boolean;

    @Prop({ default: true })
    showStatusField: boolean;

    filteredCountries: Country[] = [];
    filteredRegions: Region[] = [];
    filteredProvinces: Province[] = [];
    filteredCities: City[] = [];
    filteredPOSCategories: POSCategory[] = [];
    filteredNetworks: Network[] = [];
    nessunNetwork: Network[] = [{
        name: 'Nessuno', note: '', is_active: false
    }];
    mounted() {
        this.$fixCommonTabIndex();
    }

    get isContractActive() {
        return this.model.isContractActive();
    }

    get feeType() {
        return feeTypesForDropdown.find(x => x.code == this.model.fee_type);
    }
    set feeType(value: any) {
        this.model.fee_type = value.code;
    }

    get network() {
        return this.model.networks[0];
    }
    
    set network(value: any) {
        if (value.name == this.nessunNetwork[0].name) {
            value = null;
        }
        if (value) {
            this.model.networks = [].concat(value);
        } else {
            this.model.networks = [];
        }
    }
    
    get feeTypesForDropdown() {
        return feeTypesForDropdown;
    }

    get feeTypeLabel() {
        return feeTypesForDropdown.find(x => x.code == this.model?.fee_type)?.name || '-';
    }

    get isSuperAdmin() {
        return authStore.getters.me.role == UserRoleEnum.SUPER_ADMIN;
    }

    async searchCountry({ query }) {
        const resp = await domainService.getCountriesAutocomplete(query);
        this.filteredCountries = resp.data;
    }

    onSelectCountry({ value }, type: number) {
        if (type == 1) {
            if (value.id == this.model.country_id) return;

            this.model.country_id = value.id;

            this.model.region = null;
            this.model.region_id = null;

            this.model.province = null;
            this.model.province_id = null;

            this.model.city = null;
            this.model.city_id = null;
        }

        if (type == 2) {
            if (value.id == this.model.business_country_id) return;

            this.model.business_country_id = value.id;

            this.model.business_region = null;
            this.model.business_region_id = null;

            this.model.business_province = null;
            this.model.business_province_id = null;

            this.model.business_city = null;
            this.model.business_city_id = null;
        }
    }

    async searchRegion({ query }, type: number) {
        const resp = await domainService.getRegionsAutocomplete(query, type == 1 ? this.model.country_id : this.model.business_country_id);
        this.filteredRegions = resp.data;
    }

    onSelectRegion({ value }, type: number) {
        if (type == 1) {
            if (value.id == this.model.region_id) return;

            this.model.region_id = value.id;

            this.model.province = null;
            this.model.province_id = null;

            this.model.city = null;
            this.model.city_id = null;
        }

        if (type == 2) {
            if (value.id == this.model.business_region_id) return;

            this.model.business_region_id = value.id;

            this.model.business_province = null;
            this.model.business_province_id = null;

            this.model.business_city = null;
            this.model.business_city_id = null;
        }
    }

    async searchProvince({ query }, type: number) {
        const resp = await domainService.getProvincesAutocomplete(query, type == 1 ? this.model.region_id : this.model.business_region_id);
        this.filteredProvinces = resp.data;
    }

    onSelectProvince({ value }, type: number) {
        if (type == 1) {
            if (value.id == this.model.province_id) return;

            this.model.province_id = value.id;

            this.model.city = null;
            this.model.city_id = null;
        }

        if (type == 2) {
            if (value.id == this.model.business_province_id) return;

            this.model.business_province_id = value.id;

            this.model.business_city = null;
            this.model.business_city_id = null;
        }
    }

    async searchCity({ query }, type: number) {
        const resp = await domainService.getCitiesAutocomplete(query, type == 1 ? this.model.province_id : this.model.business_province_id);
        this.filteredCities = resp.data;
    }

    onSelectCity({ value }, type: number) {
        if (type == 1) {
            if (value.id == this.model.city_id) return;

            this.model.city_id = value.id;
        }

        if (type == 2) {
            if (value.id == this.model.business_city_id) return;

            this.model.business_city_id = value.id;
        }
    }

    async searchPOSCategory({ query }) {
        const resp = await posCategoryService.autocomplete(query);
        this.filteredPOSCategories = resp.data;
    }

    async searchNetwork({ query }) {
        const resp = await networkService.autocomplete(query);
        this.filteredNetworks = this.nessunNetwork.concat(resp.data);
    }
}