import moment from 'moment';
import { Vue, Options } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({
    name: 'DateWeekSelector'
})
export default class DateWeekSelector extends Vue {
    @Prop()
    modelValue: Date | number;

    @Prop({ default: [] })
    dates: Date[];

    @Prop({ default: [] })
    weekdays: number[];

    @Prop({ default: [] })
    disabledDates: Date[];    

    @Prop({ default: 'dates' }) // date - weekday
    mode: string;

    page: number = 1;
    
    get modelValueMoment() {
        return this.modelValue ? moment(this.modelValue) : null;
    }

    get listMoment() {
        const startIdx = (this.page * 7) - 7;
        const endIdx = (this.page * 7);
        return this.dates.map(d => moment(d)).slice(startIdx, endIdx);
    }

    get totalPages() {
        return Math.ceil(this.dates.length / 7);
    }

    selDate(date: moment.Moment) {
        if (this.isDateDisabled(date)) {
            return;
        }
        this.$emit('update:modelValue', date.toDate());
        this.$emit('changeDate', date.toDate());
    }

    selWeekday(weekday: number) {
        this.$emit('update:modelValue', weekday);
        this.$emit('changeWeekday', weekday);
    }

    nextWeek() {
        this.page++;
    }

    prevWeek() {
        this.page--;
    }

    get isNextEnabled() {
        return this.page < this.totalPages;
    }

    get isPrevEnabled() {
        return this.page > 1;
    }

    isDateDisabled(date: moment.Moment) {
        return this.disabledDates.findIndex(d => moment(d).isSame(date)) >= 0;
    }
}