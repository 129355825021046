import { Network } from '@/model/api/Network';
import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { CRUD } from './base/crud';

class NetworkService extends CRUD<Network, any> {
    readonly endPoint = `networks`;

    public autocomplete(q: string): Promise<PaginatedResponse<Network>> {
        return this.get(`${this.endPoint}/autocomplete`, { params: { q: q } });
    }
}

export const networkService = new NetworkService();