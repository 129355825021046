import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "content card" }
const _hoisted_2 = { class: "p-col-3 col-fields" }
const _hoisted_3 = { class: "p-mb-5" }
const _hoisted_4 = { class: "p-field" }
const _hoisted_5 = { class: "p-field" }
const _hoisted_6 = { class: "p-field" }
const _hoisted_7 = { class: "p-field" }
const _hoisted_8 = { class: "p-field" }
const _hoisted_9 = { class: "p-field" }
const _hoisted_10 = { class: "p-field" }
const _hoisted_11 = { class: "p-field" }
const _hoisted_12 = { class: "p-field" }
const _hoisted_13 = { class: "p-field" }
const _hoisted_14 = { class: "p-field" }
const _hoisted_15 = { class: "p-col-3 col-fields" }
const _hoisted_16 = { class: "p-mb-5" }
const _hoisted_17 = { class: "p-field" }
const _hoisted_18 = { class: "p-field" }
const _hoisted_19 = { class: "p-field" }
const _hoisted_20 = { class: "p-field" }
const _hoisted_21 = { class: "p-field" }
const _hoisted_22 = { class: "p-field" }
const _hoisted_23 = { class: "p-field" }
const _hoisted_24 = { class: "p-col-3 col-fields" }
const _hoisted_25 = { class: "p-mb-5" }
const _hoisted_26 = { class: "p-field" }
const _hoisted_27 = { class: "p-field" }
const _hoisted_28 = { class: "p-field" }
const _hoisted_29 = { class: "p-field" }
const _hoisted_30 = { class: "p-field" }
const _hoisted_31 = { class: "p-col-3 col-fields" }
const _hoisted_32 = { class: "p-mb-5" }
const _hoisted_33 = { class: "p-field" }
const _hoisted_34 = { class: "p-field" }
const _hoisted_35 = { class: "p-field" }
const _hoisted_36 = { class: "p-field" }
const _hoisted_37 = { class: "p-field" }
const _hoisted_38 = { class: "p-my-6" }
const _hoisted_39 = { class: "p-field" }
const _hoisted_40 = {
  key: 1,
  class: "regular"
}
const _hoisted_41 = { class: "p-field" }
const _hoisted_42 = {
  key: 1,
  class: "regular"
}
const _hoisted_43 = { class: "p-field" }
const _hoisted_44 = {
  key: 1,
  class: "regular"
}
const _hoisted_45 = { class: "p-field" }
const _hoisted_46 = { class: "p-field" }
const _hoisted_47 = {
  key: 1,
  class: "regular"
}
const _hoisted_48 = { class: "p-field" }
const _hoisted_49 = {
  key: 1,
  class: "regular"
}
const _hoisted_50 = { class: "p-field p-fluid" }
const _hoisted_51 = {
  key: 1,
  class: "regular"
}
const _hoisted_52 = { class: "p-field p-fluid" }
const _hoisted_53 = {
  key: 1,
  class: "regular"
}
const _hoisted_54 = {
  key: 0,
  class: "p-field"
}
const _hoisted_55 = { class: "p-field-radio" }
const _hoisted_56 = { class: "p-field-radiobutton" }
const _hoisted_57 = { for: "is_active_on" }
const _hoisted_58 = { class: "p-field-radiobutton" }
const _hoisted_59 = { for: "is_active_off" }
const _hoisted_60 = {
  key: 0,
  class: "content card"
}
const _hoisted_61 = { for: "customer_note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoIcon = _resolveComponent("InfoIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_VField = _resolveComponent("VField")!
  const _component_AutoComplete = _resolveComponent("AutoComplete")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_VError = _resolveComponent("VError")!
  const _component_VForm = _resolveComponent("VForm")!

  return (_openBlock(), _createBlock(_component_VForm, {
    class: "form_content",
    id: "edit_form"
  }, {
    default: _withCtx(({ isSubmitting }) => [
      _createVNode("section", _hoisted_1, [
        _createVNode("fieldset", {
          class: "p-grid",
          disabled: _ctx.readOnly
        }, [
          _createVNode("div", _hoisted_2, [
            _createVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('generic.general_info_title')), 1),
            _createVNode("div", _hoisted_4, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_name_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_name_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_name",
                modelValue: _ctx.model.business_name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.model.business_name = $event)),
                rules: "required"
              }, {
                default: _withCtx(({ field, meta: { valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.business_name_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_5, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.description_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.description_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "description",
                modelValue: _ctx.model.description,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.model.description = $event)),
                rules: "required"
              }, {
                default: _withCtx(({ field, meta: { valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.description_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_6, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.country_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.country_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "country_id",
                modelValue: _ctx.model.country_id,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (_ctx.model.country_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.country,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.model.country = $event)),
                    field: "name",
                    suggestions: _ctx.filteredCountries,
                    onComplete: _cache[4] || (_cache[4] = ($event: any) => (_ctx.searchCountry($event))),
                    onItemSelect: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onSelectCountry($event, 1))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_7, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.region_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.region_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "region_id",
                modelValue: _ctx.model.region_id,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (_ctx.model.region_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.region,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (_ctx.model.region = $event)),
                    field: "name",
                    suggestions: _ctx.filteredRegions,
                    onComplete: _cache[8] || (_cache[8] = ($event: any) => (_ctx.searchRegion($event, 1))),
                    onItemSelect: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onSelectRegion($event, 1))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_8, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.province_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.province_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "province_id",
                modelValue: _ctx.model.province_id,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (_ctx.model.province_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.province,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (_ctx.model.province = $event)),
                    field: "name",
                    suggestions: _ctx.filteredProvinces,
                    onComplete: _cache[12] || (_cache[12] = ($event: any) => (_ctx.searchProvince($event, 1))),
                    onItemSelect: _cache[13] || (_cache[13] = ($event: any) => (_ctx.onSelectProvince($event, 1))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_9, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.city_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.city_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "city_id",
                modelValue: _ctx.model.city_id,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (_ctx.model.city_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.city,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (_ctx.model.city = $event)),
                    field: "name",
                    suggestions: _ctx.filteredCities,
                    onComplete: _cache[16] || (_cache[16] = ($event: any) => (_ctx.searchCity($event, 1))),
                    onItemSelect: _cache[17] || (_cache[17] = ($event: any) => (_ctx.onSelectCity($event, 1))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_10, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.foreign_province_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.foreign_province_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "foreing_province",
                modelValue: _ctx.model.foreing_province,
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (_ctx.model.foreing_province = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.foreign_province_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_11, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.cap_or_zip_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.cap_or_zip_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "zip",
                modelValue: _ctx.model.zip,
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (_ctx.model.zip = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.cap_or_zip_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_12, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.address_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.address_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "address",
                modelValue: _ctx.model.address,
                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (_ctx.model.address = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.address_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_13, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.longitude_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.longitude_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "lng",
                modelValue: _ctx.model.lng,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (_ctx.model.lng = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.longitude_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_14, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.latitude_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.latitude_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "lat",
                modelValue: _ctx.model.lat,
                "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (_ctx.model.lat = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.latitude_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_15, [
            _createVNode("h3", _hoisted_16, _toDisplayString(_ctx.$t('generic.business_office_label')), 1),
            _createVNode("div", _hoisted_17, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_country_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_country_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_country_id",
                modelValue: _ctx.model.business_country_id,
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (_ctx.model.business_country_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.business_country,
                    "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (_ctx.model.business_country = $event)),
                    field: "name",
                    suggestions: _ctx.filteredCountries,
                    onComplete: _cache[25] || (_cache[25] = ($event: any) => (_ctx.searchCountry($event))),
                    onItemSelect: _cache[26] || (_cache[26] = ($event: any) => (_ctx.onSelectCountry($event, 2))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_18, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_region_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_region_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_region_id",
                modelValue: _ctx.model.business_region_id,
                "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (_ctx.model.business_region_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.business_region,
                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (_ctx.model.business_region = $event)),
                    field: "name",
                    suggestions: _ctx.filteredRegions,
                    onComplete: _cache[29] || (_cache[29] = ($event: any) => (_ctx.searchRegion($event, 2))),
                    onItemSelect: _cache[30] || (_cache[30] = ($event: any) => (_ctx.onSelectRegion($event, 2))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_19, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_province_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_province_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_province_id",
                modelValue: _ctx.model.business_province_id,
                "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => (_ctx.model.business_province_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.business_province,
                    "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => (_ctx.model.business_province = $event)),
                    field: "name",
                    suggestions: _ctx.filteredProvinces,
                    onComplete: _cache[33] || (_cache[33] = ($event: any) => (_ctx.searchProvince($event, 2))),
                    onItemSelect: _cache[34] || (_cache[34] = ($event: any) => (_ctx.onSelectProvince($event, 2))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_20, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_city_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_city_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_city_id",
                modelValue: _ctx.model.business_city_id,
                "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => (_ctx.model.business_city_id = $event)),
                rules: ""
              }, {
                default: _withCtx(({ meta: { dirty, valid } }) => [
                  _createVNode(_component_AutoComplete, {
                    forceSelection: "",
                    dropdown: "",
                    modelValue: _ctx.model.business_city,
                    "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => (_ctx.model.business_city = $event)),
                    field: "name",
                    suggestions: _ctx.filteredCities,
                    onComplete: _cache[37] || (_cache[37] = ($event: any) => (_ctx.searchCity($event, 2))),
                    onItemSelect: _cache[38] || (_cache[38] = ($event: any) => (_ctx.onSelectCity($event, 2))),
                    class: { 'p-invalid': dirty && !valid }
                  }, null, 8, ["modelValue", "suggestions", "class"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_21, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_foreign_province_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_foreign_province_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_foreing_province",
                modelValue: _ctx.model.business_foreing_province,
                "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => (_ctx.model.business_foreing_province = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.business_foreign_province_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_22, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_zip_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_zip_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_zip",
                modelValue: _ctx.model.business_zip,
                "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => (_ctx.model.business_zip = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.business_zip_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_23, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.business_address_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.business_address_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "business_address",
                modelValue: _ctx.model.business_address,
                "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => (_ctx.model.business_address = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.business_address_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_24, [
            _createVNode("h3", _hoisted_25, _toDisplayString(_ctx.$t('generic.contacts_label')), 1),
            _createVNode("div", _hoisted_26, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.pec_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.pec_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "pec",
                modelValue: _ctx.model.pec,
                "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => (_ctx.model.pec = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.pec_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_27, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.email_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.email_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "email",
                modelValue: _ctx.model.email,
                "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => (_ctx.model.email = $event)),
                rules: "required|email"
              }, {
                default: _withCtx(({ field, meta: { valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.email_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_28, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.phone_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.phone_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "phone",
                modelValue: _ctx.model.phone,
                "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => (_ctx.model.phone = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.phone_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_29, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.internal_reference_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.internal_reference_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "internal_ref",
                modelValue: _ctx.model.internal_ref,
                "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => (_ctx.model.internal_ref = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.internal_reference_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_30, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.internal_reference_phone_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.internal_reference_phone_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "phone_internal_ref",
                modelValue: _ctx.model.phone_internal_ref,
                "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => (_ctx.model.phone_internal_ref = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.internal_reference_phone_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_31, [
            _createVNode("h3", _hoisted_32, _toDisplayString(_ctx.$t('generic.billing_data')), 1),
            _createVNode("div", _hoisted_33, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.fiscal_code_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.fiscal_code_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "fiscal_code",
                modelValue: _ctx.model.fiscal_code,
                "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => (_ctx.model.fiscal_code = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.fiscal_code_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_34, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.vat_code_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.vat_code_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "vat_code",
                modelValue: _ctx.model.vat_code,
                "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => (_ctx.model.vat_code = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.vat_code_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_35, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.sdi_code_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.sdi_code_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "sdi",
                modelValue: _ctx.model.sdi,
                "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => (_ctx.model.sdi = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.sdi_code_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_36, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.iban_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.iban_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "iban",
                modelValue: _ctx.model.iban,
                "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => (_ctx.model.iban = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.iban_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_37, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.bank_name_label')) + " ", 1),
                _createVNode(_component_InfoIcon, { text: "generic.bank_name_label.tooltip" })
              ]),
              _createVNode(_component_VField, {
                name: "bank_name",
                modelValue: _ctx.model.bank_name,
                "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => (_ctx.model.bank_name = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: [{ 'p-invalid': dirty && !valid }, "p-input-filled"],
                    placeholder: _ctx.$t('generic.bank_name_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("h3", _hoisted_38, _toDisplayString(_ctx.$t('generic.contract_data')), 1),
            _createVNode("div", _hoisted_39, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.contract_activation_date_label')) + " ", 1),
                (_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "generic.contract_activation_date_label.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_Calendar, {
                    key: 0,
                    modelValue: _ctx.model.start_contract_at,
                    "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => (_ctx.model.start_contract_at = $event)),
                    showButtonBar: true,
                    monthNavigator: true,
                    yearNavigator: true,
                    yearRange: "1950:2040"
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_40, [
                    _createVNode(_component_DateFormatted, {
                      value: _ctx.model.start_contract_at,
                      format: "DD/MM/YYYY"
                    }, null, 8, ["value"])
                  ]))
            ]),
            _createVNode("div", _hoisted_41, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.contract_expiry_date_label')) + " ", 1),
                (_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "generic.contract_expiry_date_label.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_Calendar, {
                    key: 0,
                    modelValue: _ctx.model.end_contract_at,
                    "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => (_ctx.model.end_contract_at = $event)),
                    showButtonBar: true,
                    monthNavigator: true,
                    yearNavigator: true,
                    yearRange: "1950:2040"
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_42, [
                    _createVNode(_component_DateFormatted, {
                      value: _ctx.model.end_contract_at,
                      format: "DD/MM/YYYY"
                    }, null, 8, ["value"])
                  ]))
            ]),
            _createVNode("div", _hoisted_43, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.apply.commitions.at')) + " ", 1),
                (_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "generic.apply.commitions.at.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_Calendar, {
                    key: 0,
                    modelValue: _ctx.model.apply_commissions_at,
                    "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => (_ctx.model.apply_commissions_at = $event)),
                    showButtonBar: true,
                    monthNavigator: true,
                    yearNavigator: true,
                    yearRange: "1950:2040"
                  }, null, 8, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_44, [
                    _createVNode(_component_DateFormatted, {
                      value: _ctx.model.apply_commissions_at,
                      format: "DD/MM/YYYY"
                    }, null, 8, ["value"])
                  ]))
            ]),
            _createVNode("div", _hoisted_45, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('admin.pos.detail.contract_status_label')) + ": ", 1),
                _createVNode("span", {
                  class: ["regular", { 'status-active': _ctx.isContractActive, 'status-inactive': !_ctx.isContractActive }]
                }, _toDisplayString(_ctx.isContractActive ? _ctx.$t('active_label') : _ctx.$t('inactive_label')), 3)
              ])
            ]),
            _createVNode("div", _hoisted_46, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.fee_type_label')) + " ", 1),
                (_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "generic.fee_type_label.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_Dropdown, {
                    key: 0,
                    modelValue: _ctx.feeType,
                    "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => (_ctx.feeType = $event)),
                    options: _ctx.feeTypesForDropdown,
                    optionLabel: "name",
                    disabled: _ctx.readOnly
                  }, null, 8, ["modelValue", "options", "disabled"]))
                : (_openBlock(), _createBlock("label", _hoisted_47, _toDisplayString(_ctx.feeTypeLabel), 1))
            ]),
            _createVNode("div", _hoisted_48, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('generic.fee_amount_label')) + " ", 1),
                (_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "generic.fee_amount_label.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_VField, {
                    key: 0,
                    name: "fee",
                    modelValue: _ctx.model.fee,
                    "onUpdate:modelValue": _cache[57] || (_cache[57] = ($event: any) => (_ctx.model.fee = $event)),
                    modelModifiers: { number: true }
                  }, {
                    default: _withCtx(({ field, meta: { dirty, valid } }) => [
                      _createVNode(_component_InputText, _mergeProps(field, {
                        disabled: isSubmitting,
                        step: 0.01,
                        type: "number",
                        class: { 'p-invalid': dirty && !valid }
                      }), null, 16, ["disabled", "step", "class"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_49, _toDisplayString(_ctx.model.fee), 1))
            ]),
            _createVNode("div", _hoisted_50, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('admin.pos.detail.categories_label')) + " ", 1),
                (_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "admin.pos.detail.categories_label.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_VField, {
                    key: 0,
                    name: "pos_categories",
                    modelValue: _ctx.model.pos_categories,
                    "onUpdate:modelValue": _cache[60] || (_cache[60] = ($event: any) => (_ctx.model.pos_categories = $event)),
                    rules: "required"
                  }, {
                    default: _withCtx(({ meta: { dirty, valid } }) => [
                      _createVNode(_component_AutoComplete, {
                        forceSelection: "",
                        dropdown: "",
                        multiple: true,
                        modelValue: _ctx.model.pos_categories,
                        "onUpdate:modelValue": _cache[58] || (_cache[58] = ($event: any) => (_ctx.model.pos_categories = $event)),
                        field: "name",
                        suggestions: _ctx.filteredPOSCategories,
                        onComplete: _cache[59] || (_cache[59] = ($event: any) => (_ctx.searchPOSCategory($event))),
                        class: { 'p-invalid': dirty && !valid },
                        disabled: _ctx.readOnly
                      }, null, 8, ["modelValue", "suggestions", "class", "disabled"])
                    ]),
                    _: 1
                  }, 8, ["modelValue"]))
                : (_openBlock(), _createBlock("label", _hoisted_51, _toDisplayString(_ctx.model.posCategoriesLabel), 1))
            ]),
            _createVNode("div", _hoisted_52, [
              _createVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('pos.profile.networks_label')) + " ", 1),
                (_ctx.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_InfoIcon, {
                      key: 0,
                      text: "pos.profile.networks_label.tooltip"
                    }))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.isSuperAdmin)
                ? (_openBlock(), _createBlock(_component_AutoComplete, {
                    key: 0,
                    dropdown: "",
                    multiple: false,
                    modelValue: _ctx.network,
                    "onUpdate:modelValue": _cache[61] || (_cache[61] = ($event: any) => (_ctx.network = $event)),
                    field: "name",
                    suggestions: _ctx.filteredNetworks,
                    onComplete: _cache[62] || (_cache[62] = ($event: any) => (_ctx.searchNetwork($event))),
                    disabled: _ctx.readOnly
                  }, null, 8, ["modelValue", "suggestions", "disabled"]))
                : (_openBlock(), _createBlock("label", _hoisted_53, _toDisplayString(_ctx.network?.name), 1))
            ]),
            (_ctx.isSuperAdmin && _ctx.showStatusField)
              ? (_openBlock(), _createBlock("div", _hoisted_54, [
                  _createVNode("label", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('generic.input_state_label')) + " ", 1),
                    (_ctx.isSuperAdmin)
                      ? (_openBlock(), _createBlock(_component_InfoIcon, {
                          key: 0,
                          text: "generic.input_state_label.tooltip"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _createVNode("div", _hoisted_55, [
                    _createVNode("div", _hoisted_56, [
                      _createVNode(_component_RadioButton, {
                        id: "is_active_on",
                        modelValue: _ctx.model.is_active,
                        "onUpdate:modelValue": _cache[63] || (_cache[63] = ($event: any) => (_ctx.model.is_active = $event)),
                        value: true
                      }, null, 8, ["modelValue"]),
                      _createVNode("label", _hoisted_57, _toDisplayString(_ctx.$t('active_label')), 1)
                    ]),
                    _createVNode("div", _hoisted_58, [
                      _createVNode(_component_RadioButton, {
                        id: "is_active_off",
                        modelValue: _ctx.model.is_active,
                        "onUpdate:modelValue": _cache[64] || (_cache[64] = ($event: any) => (_ctx.model.is_active = $event)),
                        value: false
                      }, null, 8, ["modelValue"]),
                      _createVNode("label", _hoisted_59, _toDisplayString(_ctx.$t('inactive_label')), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ], 8, ["disabled"])
      ]),
      (_ctx.model)
        ? (_openBlock(), _createBlock("section", _hoisted_60, [
            _createVNode("label", _hoisted_61, [
              _createTextVNode(_toDisplayString(_ctx.$t('pos.profile.customer_note_label')) + " ", 1),
              _createVNode(_component_InfoIcon, { text: "pos.profile.customer_note_label.tooltip" })
            ]),
            _createVNode(_component_VField, {
              name: "pos_note",
              label: "Presentazione",
              modelValue: _ctx.model.note,
              "onUpdate:modelValue": _cache[65] || (_cache[65] = ($event: any) => (_ctx.model.note = $event)),
              rules: "max:1000"
            }, {
              default: _withCtx(({ field, meta: { valid } }) => [
                _createVNode(_component_Textarea, _mergeProps(field, {
                  disabled: isSubmitting,
                  class: [{ 'p-invalid': !valid }, "p-mt-1"],
                  placeholder: _ctx.$t('pos.profile.customer_note_label'),
                  rows: "3",
                  cols: "40"
                }), null, 16, ["disabled", "class", "placeholder"])
              ]),
              _: 2
            }, 1032, ["modelValue"]),
            _createVNode(_component_VError, {
              class: "p-error",
              name: "pos_note"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}