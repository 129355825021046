import { PaginatedResponse } from '@/model/api/PaginatedResponse';
import { POSService } from '@/model/api/POS';
import { IBaseApiAttachmentModel } from '@/model/common/BaseApiModel';
import { AttachmentTypeEnum } from '@/model/enums/AttachmentTypeEnum';
import axios from 'axios';
import { CRUD } from './base/crud';

class POSServiceService extends CRUD<POSService, any> {
    readonly endPoint = `pos-services`;

    public indexByPOS(posId: number, params?: any) {
        if (params.filters) {
            params.filters = this.getFilterParam(params.filters);
        }
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        return this.get<PaginatedResponse<POSService>>(`pos/${posId}/services`, { params, cancelToken });
    }

    public getAttachments(posServiceId: number, type?: AttachmentTypeEnum) {
        this.source = axios.CancelToken.source();
        const cancelToken = this.source.token;
        return this.get<IBaseApiAttachmentModel[]>(`${this.endPoint}/${posServiceId}/attachments`, {
            params: {
                type: type,
                cancelToken
            }
        });
    }

    uploadAttachment(posServiceId: number, file: File, description: string, type: string) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);
        formData.append("description", description);

        const options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            // onUploadProgress: onUploadProgress
        };

        return this.post<any>(`${this.endPoint}/${posServiceId}/upload-attachment`, formData, options);
    }


    deleteAttachment(posAttachId: number) {
        return this.delete<void>(`pos-services-attachments/${posAttachId}`);
    }
}

export const posServiceService = new POSServiceService();